module.exports = [{
      plugin: require('/Users/luce/Documents/my-site/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/luce/Documents/my-site/node_modules/gatsby-plugin-emotion/gatsby-browser.js'),
      options: {"plugins":[],"autoLabel":false,"labelFormat":"[filename]--[local]"},
    },{
      plugin: require('/Users/luce/Documents/my-site/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
