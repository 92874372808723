// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/luce/Documents/my-site/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-tags-jsx": () => import("/Users/luce/Documents/my-site/src/pages/tags.jsx" /* webpackChunkName: "component---src-pages-tags-jsx" */),
  "component---src-templates-tag-jsx": () => import("/Users/luce/Documents/my-site/src/templates/tag.jsx" /* webpackChunkName: "component---src-templates-tag-jsx" */),
  "component---src-templates-post-jsx": () => import("/Users/luce/Documents/my-site/src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-pages-404-jsx": () => import("/Users/luce/Documents/my-site/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("/Users/luce/Documents/my-site/src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-jsx": () => import("/Users/luce/Documents/my-site/src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-contact-jsx": () => import("/Users/luce/Documents/my-site/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-events-jsx": () => import("/Users/luce/Documents/my-site/src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-index-jsx": () => import("/Users/luce/Documents/my-site/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-team-jsx": () => import("/Users/luce/Documents/my-site/src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/luce/Documents/my-site/.cache/data.json")

